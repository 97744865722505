@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #000300;
}

.background-video {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5;
  min-width: 1920px;
}
.custom-navbar {
  postion: sticky;
  top: 0;
}

@media screen and (max-width: 574px) {
  .custom-navbar {
    background-color: #060504;
  }
}

@media screen and (max-width: 574px) {
  .slider-wrapper{
    height: 192px!important;
  }
}



